<template>
  <div class="col">
    <div class="card">
      <!-- Accordion  -->
      <el-collapse accordion>
        <el-collapse-item
          v-for="team in usersGroupsData"
          :key="team.id"
          :title="team.name"
          :name="team.id"
        >
          <!-- Start Header  -->
          <template slot="title">
            <div class="wrapper_collapse_for_header">
              <div class="head_part_one">
                <span class="d-block user-name text-capitalize">
                  {{ team.name }}
                </span>
              </div>
            </div>
          </template>
          <!-- End Header  -->
          <div>
            <el-table :data="team.users">
              <el-table-column
                :label="$t('admin_dashboard_type.name')"
                prop="name"
                align="right"
              />
              <el-table-column
                :label="$t('admin_dashboard_type.pre_exam')"
                min-width="200"
              >
                <template slot-scope="scope">
                  <div>
                    <router-link :to="`/neomi-exam/${scope.row.id}/3/1`">
                      <el-button class="btn btn-primary">
                        {{ $t("admin_dashboard_type.pre_exam") }}
                      </el-button>
                    </router-link>
                    <el-button
                      class="btn btn-danger mr-2 ml-2"
                      @click="openPreExam(scope.row.id)"
                    >
                      {{ $t("delete") }}
                    </el-button>
                    <el-button
                      v-if="!hasPermission('mark_neomi_test')"
                      class="btn btn-success mr-2"
                      @click="openPreExamPrintDialog(scope.row.id)"
                    >
                      {{ $t("Print") }}
                    </el-button>
                    <!-- hasPermission('mark_neomi_test')   v-if="hasPermission('mark_neomi_test')" -->
                    <el-button
                      class="btn btn-success mr-2"
                      @click="openPreExamPrintDialogTwo(scope.row.id)"
                    >
                      {{ $t("exam_correction") }}
                    </el-button>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="student_id"
                :label="$t('admin_dashboard_type.post_exam')"
                min-width="200"
                align="right"
              >
                <template slot-scope="scope">
                  <div>
                    <router-link :to="`/neomi-exam/${scope.row.id}/4/1`">
                      <el-button class="btn btn-primary">
                        {{ $t("admin_dashboard_type.post_exam_result") }}
                      </el-button>
                    </router-link>
                    <el-button
                      class="btn btn-danger mr-2 ml-2"
                      @click="openLastExam(scope.row.id)"
                    >
                      {{ $t("delete") }}
                    </el-button>

                    <el-button
                      v-if="!hasPermission('mark_neomi_test')"
                      class="btn btn-success mr-2"
                      @click="openLastExamPrintDialog(scope.row.id)"
                    >
                      {{ $t("Print") }}
                    </el-button>
                    <!-- v-if="hasPermission('mark_neomi_test')" v-if="hasPermission('mark_neomi_test')" -->
                    <el-button
                      class="btn btn-success mr-2"
                      @click="openLastExamPrintDialogTwo(scope.row.id)"
                    >
                      {{ $t("exam_correction") }}
                    </el-button>
                    <!-- neomi-exams/:student_id/challenge_no/exam_type -->
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>

    <!--  Dialog  -->
    <el-dialog
      :title="switchTitle"
      width="50%"
      :visible.sync="open_n_dialog"
      top="4vh"
      class="text-right text-capitalize"
      :show-close="false"
    >
      <el-form
        :model="nemoiData"
        ref="editRef"
        :rules="rules"
        label-position="top"
      >
        <el-form-item :label="$t('challenge_type')" prop="challenge_no">
          <el-radio-group v-model="nemoiData.challenge_no">
            <el-radio :label="1" @click="nemoiData.challenge_no = 1">{{
              $t("admin_dashboard_type.challenge_one")
            }}</el-radio>
            <el-radio :label="2" @click="nemoiData.challenge_no = 2">{{
              $t("admin_dashboard_type.challenge_two")
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog_footers" dir="ltr">
        <el-button
          class="text-center px-4 px-lg-3 ml-lg-2 mx-auto d-block text-capitalize w-100 my-1"
          type="primary"
          @click="resetTimer"
          :disabled="nemoiData.challenge_no == null"
        >
          {{ $t("Reset time") }}
        </el-button>
        <el-button
          class="text-center px-4 px-lg-3 ml-lg-2 mx-auto d-block text-capitalize w-100 my-1"
          type="primary"
          @click="redoTest"
          :disabled="nemoiData.challenge_no == null"
        >
          {{ $t("Re-examination") }}
        </el-button>
        <el-button
          class="text-center ml-lg-2 mx-auto d-block w-100 my-1"
          @click="open_n_dialog = false"
          >{{ $t("cancel") }}
        </el-button>
      </div>
    </el-dialog>
    <!-- Start Print Dialog One -->
    <el-dialog
      :title="switchPrintTitle"
      width="30%"
      :visible.sync="printDialog"
      top="4vh"
      class="text-right"
      :show-close="false"
    >
      <el-form
        :model="printData"
        ref="editRef"
        :rules="rules"
        label-position="top"
      >
        <el-form-item :label="$t('challenge_type')" prop="challenge_no">
          <el-radio-group v-model="printData.challenge_no">
            <el-radio :label="1" @click="printData.challenge_no = 1">{{
              $t("admin_dashboard_type.challenge_one")
            }}</el-radio>
            <el-radio :label="2" @click="printData.challenge_no = 2">
              {{ $t("admin_dashboard_type.challenge_two") }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog_footers" dir="ltr">
        <el-button
          class="text-center px-4 px-lg-3 ml-lg-2 mx-auto d-block text-capitalize w-100 my-1"
          type="primary"
          :disabled="printData.challenge_no == null"
          @click="goToPrintExamPage"
        >
          {{ $t("show_challenge") }}
        </el-button>
        <el-button
          class="text-center ml-lg-2 mx-auto d-block w-100 my-1"
          @click="printDialog = false"
          >{{ $t("cancel") }}
        </el-button>
      </div>
    </el-dialog>
    <!-- End Print Dialog One -->

    <!-- Start Print Dialog Two -->
    <el-dialog
      :title="switchPrintTitleTwo"
      width="30%"
      :visible.sync="printDialogTwo"
      top="4vh"
      class="text-right"
      :show-close="false"
    >
      <el-form
        :model="printDataTwo"
        ref="editRef"
        :rules="rules"
        label-position="top"
      >
        <el-form-item :label="$t('challenge_type')" prop="challenge_no">
          <el-radio-group v-model="printDataTwo.challenge_no">
            <el-radio :label="1" @click="printDataTwo.challenge_no = 1">
              {{ $t("admin_dashboard_type.challenge_one") }}
            </el-radio>
            <el-radio :label="2" @click="printDataTwo.challenge_no = 2">
              {{ $t("admin_dashboard_type.challenge_two") }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog_footers" dir="ltr">
        <el-button
          class="text-center px-4 px-lg-3 ml-lg-2 mx-auto d-block text-capitalize w-100 my-1"
          type="primary"
          :disabled="printDataTwo.challenge_no == null"
          @click="goToPrintExamPageTwo"
        >
          {{ $t("show_challenge") }}
        </el-button>
        <el-button
          class="text-center ml-lg-2 mx-auto d-block w-100 my-1"
          @click="printDialogTwo = false"
          >{{ $t("cancel") }}
        </el-button>
      </div>
    </el-dialog>
    <!-- End Print Dialog Two -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      switchTitle: "",
      nemoiData: {
        student_id: null,
        challenge_no: null,
        exam_type: null,
      },
      rules: {
        challenge_no: [
          {
            required: true,
            message: this.$t("name_required"),
            trigger: "blur",
          },
        ],
      },
      open_n_dialog: false,
      checkboxData: [
        "Original",
        "Flex",
        "Flue",
        "Details",
        "Imagination",
        "Title",
      ],
      printDialog: false,
      printDialogTwo: false,
      switchPrintTitle: "",
      switchPrintTitleTwo: "",
      printData: {
        student_id: null,
        challenge_no: null,
        exam_type: null,
      },
      printDataTwo: {
        student_id: null,
        challenge_no: null,
        exam_type: null,
      },
    };
  },
  mounted() {
    this.refreshData();
  },
  computed: {
    usersExamsData() {
      return this.$store.getters["groups/neomiExamsData"];
    },
    usersGroupsData() {
      return this.$store.getters["groups/neomiGroupsData"];
    },
    printPdfReportData() {
      return this.$store.getters["groups/printPdfReportData"];
    },
  },
  methods: {
    refreshData() {
      this.$store.dispatch("groups/getNeomiExamsData");
      this.$store.dispatch("groups/getNeomiWithGroupsData");
    },
    resetTimer() {
      this.$store
        .dispatch("groups/resetNemoiTimer", this.nemoiData)
        .then(() => {
          this.open_n_dialog = false;
          this.nemoiData.challenge_no = null;
          Swal.fire({
            text: "تمت إعادة تعيين الوقت",
            icon: "success",
            showCancelButton: false,
            confirmButtonClass: "btn btn-success btn-fill",
            confirmButtonText: this.$t("confirm"),
            buttonsStyling: false,
          }).then((date) => {
            if (date.isConfirmed) {
              this.refreshData();
            }
          });
        });
    },
    redoTest() {
      this.$store.dispatch("groups/redoNemoiTest", this.nemoiData).then(() => {
        this.open_n_dialog = false;
        this.nemoiData.challenge_no = null;
        Swal.fire({
          text: "تمت إعادة الامتحان",
          icon: "success",
          showCancelButton: false,
          confirmButtonClass: "btn btn-success btn-fill",
          confirmButtonText: this.$t("confirm"),
          buttonsStyling: false,
        }).then((date) => {
          if (date.isConfirmed) {
            this.refreshData();
          }
        });
      });
    },
    openPreExam(id) {
      this.switchTitle = `${this.$t("pre_exam")}`;
      this.open_n_dialog = true;
      this.nemoiData.student_id = id;
      this.nemoiData.exam_type = 3;
    },
    openLastExam(id) {
      this.switchTitle = `${this.$t("last_exam")}`;
      this.open_n_dialog = true;
      this.nemoiData.student_id = id;
      this.nemoiData.exam_type = 4;
    },
    // Start Print One
    goToPrintExamPage() {
      this.$router.push(
        `/neomi-exams/${this.printData.student_id}/${this.printData.challenge_no}/${this.printData.exam_type}`
      );

      this.printDialog = false;
      this.printData.student_id = null;
      this.printData.exam_type = null;
      this.printData.challenge_no = null;
    },
    openPreExamPrintDialog(id) {
      this.switchPrintTitle = `${this.$t("pre_exam")}`;
      this.printDialog = true;
      this.printData.student_id = id;
      this.printData.exam_type = 3;
    },
    openLastExamPrintDialog(id) {
      this.switchPrintTitle = `${this.$t("last_exam")}`;
      this.printDialog = true;
      this.printData.student_id = id;
      this.printData.exam_type = 4;
    },
    // End Print One

    // Start Print Two
    goToPrintExamPageTwo() {
      this.$router.push(
        `/neomi-exam-correct/${this.printDataTwo.student_id}/${this.printDataTwo.challenge_no}/${this.printDataTwo.exam_type}`
      );

      this.printDialogTwo = false;
      this.printDataTwo.student_id = null;
      this.printDataTwo.exam_type = null;
      this.printDataTwo.challenge_no = null;
    },
    openPreExamPrintDialogTwo(id) {
      this.switchPrintTitleTwo = `${this.$t("admin_dashboard_type.pre_exam")}`;

      this.printDialogTwo = true;
      this.printDataTwo.student_id = id;
      this.printDataTwo.exam_type = 3;
    },
    openLastExamPrintDialogTwo(id) {
      this.switchPrintTitleTwo = `${this.$t("admin_dashboard_type.post_exam")}`;
      this.printDialogTwo = true;
      this.printDataTwo.student_id = id;
      this.printDataTwo.exam_type = 4;
    },
    // End Print Two
  },
};
</script>

<style lang="scss" scoped>
.wrapper_collapse_for_header {
  padding: 10px;
}
.dialog_footers {
  display: flex;
  flex-direction: row-reverse;
}
::v-deep .el-radio__label {
  margin-right: 8px;
  justify-content: space-between;
}
::v-deep .cell {
  text-align: center;
  word-break: break-word;
}
</style>
